import { FETCH_PRIVATE_PLAYLISTS } from "./actions"
import { PaginatedData } from "../../types/root"
import { PlaylistsActionProps, PlaylistsProps, PlayListType } from "types/playlists"

export const initialPlaylistsState: PlaylistsProps = {
  paginatedPlaylists: new PaginatedData<PlayListType>()
}

const privatePlaylists = (state = initialPlaylistsState, action: PlaylistsActionProps) => {
  switch (action.type) {
    case FETCH_PRIVATE_PLAYLISTS: {
      const paginatedPlaylists =
        (action.payload?.paginatedPlaylists as PaginatedData<PlayListType>) || new PaginatedData<PlayListType>()
      return {
        ...state,
        paginatedPlaylists
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default privatePlaylists
