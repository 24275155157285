import { createContext, ReactElement, useReducer } from "react"
import axios from "utils/axios"
import { FETCH_PRIVATE_PLAYLISTS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { PlaylistsContextType, PlayListType } from "types/playlists"
import playlistsReducer, { initialPlaylistsState } from "store/reducers/playlists"

const PlaylistsContext = createContext<PlaylistsContextType | null>(null)

export const PlaylistsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(playlistsReducer, initialPlaylistsState)

  const fetchPrivatePlaylists = async (pageNumber: number, pageSize: number, queryString?:string) => {
    
    //pageSize is not working correctly in this endpoint related api endpoint. filters are not applicable to the related endpoint as well.

    const filters = queryString ? `&${queryString}` : ""
    const response = await axios.get(
      `/public/stock-music/playlists/?page_number=${pageNumber}&page_size=${pageSize}${filters}&private=1`,
      { withCredentials: true }
    )
    
    dispatch({
      type: FETCH_PRIVATE_PLAYLISTS,
      payload: { ...state, paginatedPlaylists: mapPaginatedDataToCamelCase<PaginatedData<PlayListType>>(response.data) }
    })
  }

  return <PlaylistsContext.Provider value={{ ...state, fetchPrivatePlaylists }}>{children}</PlaylistsContext.Provider>
}

export default PlaylistsContext
