import Routes from "routes"
import ThemeCustomization from "themes"
import Locales from "components/Locales"
import RTLLayout from "components/RTLLayout"
import ScrollTop from "components/ScrollTop"
import Snackbar from "components/@extended/Snackbar"
import Notistack from "components/third-party/Notistack"
import { JWTProvider as AuthProvider } from "contexts/JWTContext"
import { TracksProvider } from "./contexts/TracksContext"
import { ArtistsProvider } from "./contexts/ArtistsContext"
import { CompaniesProvider } from "contexts/CompaniesContext"
import { PlaylistsProvider } from "contexts/PlaylistsContext"

const App = () => {
  /*const [loading, setLoading] = useState<boolean>(true)

  if (loading) return <Loader />*/

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <ArtistsProvider>
                <TracksProvider>
                  <PlaylistsProvider>
                    <CompaniesProvider>
                      <Notistack>
                        <Routes />
                        <Snackbar />
                      </Notistack>
                    </CompaniesProvider>
                  </PlaylistsProvider>
                </TracksProvider>
              </ArtistsProvider>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  )
}

export default App
